var stickyHeader = true;
var transformHomepageDonationAmountImages = true;
// var flyoutNav = false;
// var fullscreenNav = false;
var homeQuickGivingPreFooter = true;
var countUpStats = true;
var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;


// Card no baner
var $card = $(".listedPost, .feedItem");
var $cardDetail = $(".listedPostText, .feedItemDetailsWrapper");

$card.each( function() {
  if (($(this).find('.banner').length === 0)) {
      $(this).find($cardDetail).css("margin-top", "0");
  }
});